/* src/components/CookieConsent.css */
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000080;
  color: #fff;
  text-align: center;
  margin-top: 120px;

  padding: 15px;
  z-index: 1000;
}

.cookie-consent-content {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.cookie-consent p {
  margin: 0;
  padding: 0;
}

.cookie-consent a {
  color: rgb(235, 125, 250);
  text-decoration: underline;
}

.cookie-consent button {
  background-color: #3bcdf1;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.cookie-consent button:hover {
  background-color: #bb5ed8;
}
