.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-div {
  width: 100px;
  height: 100px;
  background-color: #612cd9;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

.ant-spin-dot-item {
  background-color: #612cd9;
}
.ant-collapse .ant-collapse-content {
  background-color: rgba(9, 9, 10, 0.2) !important; /* Optional `!important` */
}
h4.ant-typography {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  color: #ffffff;
}
.custom-card .ant-card-body {
  padding: 0px !important; /* Adjust padding as needed */
  border-radius: 0 0 8px 8px !important; /* Adjust border radius */
  border-top: none !important; /* Remove the top border */
  background: rgba(255, 255, 255, 0.005);
}
.main-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "white";
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  display: block;
  margin: 0px !important;
  font-family: "Roboto", sans-serif;
}

.header-color {
  background: rgb(208, 31, 223);
  background: linear-gradient(
    120deg,
    rgba(208, 31, 223, 1) 0%,
    rgba(0, 0, 128, 1) 35%,
    rgba(18, 221, 221, 1) 100%
  );
}

.tags-color {
  background: rgba(42, 147, 211);
  background: linear-gradient(
    90deg,
    rgba(42, 147, 211) 5%,
    rgba(0, 0, 128) 95%
  );
}

.types-color {
  background: rgba(208, 31, 223);
  background: linear-gradient(
    90deg,
    rgba(208, 31, 223) 5%,
    rgba(136, 55, 228) 95%
  );
}

.home-page-color {
  background: rgb(0, 0, 128, 1);
}

.ant-menu-light .ant-menu-item {
  color: white !important;
}

.ant-menu-submenu {
  color: white;
}

.ant-menu-light.ant-menu-submenu-popup > .ant-menu,
.ant-menu-light > .ant-menu.ant-menu-submenu-popup > .ant-menu {
  background-color: #03183f;
}

.ant-menu-title-content:hover {
  color: rgb(208, 31, 223);
}

.ant-menu-title-content:hover,
.ant-menu-title-content:hover .anticon {
  color: rgb(208, 31, 223);
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active:hover,
.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-horizontal > .ant-menu-item:hover > a,
.ant-menu-horizontal > .ant-menu-item-active:hover > a,
.ant-menu-horizontal > .ant-menu-item-selected:hover > a,
.ant-menu-horizontal > .ant-menu-item:hover > span,
.ant-menu-horizontal > .ant-menu-item-active:hover > span,
.ant-menu-horizontal > .ant-menu-item-selected:hover > span {
  color: rgb(208, 31, 223);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: rgb(208, 31, 223);
}

.ant-menu-light .ant-menu-item,
.ant-menu-light > .ant-menu .ant-menu-item,
.ant-menu-light .ant-menu-submenu-title,
.ant-menu-light > .ant-menu .ant-menu-submenu-title {
  color: white;
}

.ant-menu-item:hover .anticon.anticon-user,
.ant-menu-item:hover {
  color: rgb(208, 31, 223);
}

.anticon.anticon-shopping-cart .ant-menu-item-icon:hover {
  color: rgb(208, 31, 223);
}

.ant-menu-item:hover .anticon.anticon-shopping-cart,
.ant-menu-item:hover {
  color: rgb(208, 31, 223);
}

.ant-menu-title-content:hover,
.ant-menu-title-content:hover .anticon.anticon-shopping-cart {
  color: rgb(208, 31, 223);
}
.work-sans-moderation {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
